import * as ReferrerHelper from './common/utils/ReferrerHelper';

(function () {
    'use strict';

    function main() {

        var controller = {
            init: function () {
                var widget = wo$('[data-widget-type="mobile-phone-search"]');
                if (!widget.length) return;

                widget.find('#change-usage').off('click').on('click', function () {
                    WhistleOut.trackEvent('MobilePhoneSearch', 'ChangeUsage');
                });
                widget.find('#view-full-results').off('click').on('click', function () {
                    WhistleOut.trackEvent('MobilePhoneSearch', 'ViewFullResults');
                });
                widget.find('[data-transaction]').off('click').each(function () {
                    const $this = wo$(this);
                    const transaction = $this.data('transaction');
                    const supplier = $this.data('supplier');
                    if (transaction === 'GoToSite') {
                        const href = $this.attr('href');

                        $this.attr('href', href + (href.indexOf('?') === -1 ? '?' : '&') + 'r=' + ReferrerHelper.getReferrerEncoded());
                    }
                    $this.on('click', function () {
                        WhistleOut.trackEvent('MobilePhoneSearch', transaction, supplier);
                    });
                });
            }
        };

        WhistleOut.initWidget = controller.init;
        controller.init();
    };

    WhistleOut.initModule(import.meta.url, main);

}());